// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Link from '../components/link'
import '../components/link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Vision',
  slug: '/vision',
  abstract:
    'The Aura is a conscious evolution of Auroville economics, away from an individualistic life of survival, evolving towards a life of creativity and communal harmony. Rediscovering the meaning of money in human society, Aura is created as a virtual credit and digital currency that translates human goodwill and care into the exchange of value.',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'Vision', slug: '/vision' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    pic1: file(relativePath: { eq: "vision/pic1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    pic2: file(relativePath: { eq: "vision/pic2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    pic3: file(relativePath: { eq: "vision/pic3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper pageSchema={pageSchema} {...props}>
    <div className="container">
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
        style={{
          borderTop: '4px solid var(--gold2)',
          width: '100%',
          marginBottom: '1.1rem',
          padding: '0rem',
        }}
      >
        <Col
          xs={24}
          sm={24}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          style={{ padding: '0.55rem' }}
        >
          <h1 className="responsive-right" style={{ lineHeight: 1 }}>
            Vision
          </h1>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={19}
          lg={19}
          xl={19}
          xxl={19}
          style={{ padding: '0.55rem' }}
        >
          <p>
            The Aura is a conscious evolution of Auroville economics, away from
            an individualistic life of survival, evolving towards a life of
            creativity and communal harmony. Rediscovering the meaning of money
            in human society, Aura is created as a virtual credit and digital
            currency that translates human goodwill and care into the exchange
            of value.
          </p>
          <p>
            The Aura Network goes for a cashless community, a local economy that
            empowers individuals for a life of freedom and joy. As members of
            the community discover the abundance in their lives, they naturally
            share with each other what they value. An unconditional sharing and
            interdependence no longer remains an unreachable ideal but a living
            reality.
          </p>
          <p>
            As the flow of resources bridges the gaps between the needs of the
            people, the economy becomes circular, as is intended in the
            evolution of humanity. A conscious flow of sharing and receiving
            brings a sense of sustainability and an alignment with the cycles of
            nature.
          </p>
          <p>
            The natural flow of life follows the fundamental law of
            impermanence. You get ‘free’ credits, in the same way nature gives
            you. You ‘share’ and bring joy, just like the cycles of a balanced
            ecosystem. You ‘circulate’ what you don’t use, just like the cycle
            of life and death in nature.
          </p>
          <p>
            With a circular flow of valuable resources, the team discovered a
            new way of perception. The movement of freely available resources
            could be seen as a sustainable cycle and called ‘Circular Basic
            Income.’
          </p>
          <p>
            When we realize the interconnectedness of life, and share, a
            fulfillment naturally arises. The system is a conscious use of
            technology to bring us together, to share the prosperity and
            abundance we already have, but are not aware of.
          </p>
          <p>
            The project team underwent two years of intense collaboration to
            realize the dream of the Aura Network App; to help create for the
            members of the community a sense of belongingness and an opportunity
            to care for each other.
          </p>
        </Col>
      </Row>
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
        style={{
          borderTop: '4px solid var(--gold2)',
          width: '100%',
          marginBottom: '1.1rem',
          padding: '0rem',
        }}
      >
        <Col
          xs={24}
          sm={24}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          style={{ padding: '0.55rem' }}
        >
          <h2 className="responsive-right">Collaborative Effort</h2>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={19}
          lg={19}
          xl={19}
          xxl={19}
          style={{ padding: '0.55rem' }}
        >
          <p>
            In the beginning of 2018, the Korean Pavilion came across a
            revolutionary global potential and contacted Professor Cho at&nbsp;
            <Link to="http://sciencewalden.org/en">
              Science Walden Centre in Korea
            </Link>
            .
          </p>
          <p>
            He had created monetary value out of an under-utilized human
            resource – poop! With a provocative truth, he addressed the divorce
            of humanity from the cycle of nature and life. He discovered a way
            to bring back prosperity into the lives of people.
          </p>
          <p>
            He developed a toilet that would convert a person’s daily feces into
            an asset: compost and biogas. This ‘hi-tech toilet’ gives the users
            credit on their smartphone using the feces Standard Money app for
            every contribution. These virtual credits have the potential to be a
            global currency.
          </p>
          <p>
            Since the hi-tech toilet is not yet at an implementable stage, the
            team looked at this brilliant idea as a metaphor for under-utilized
            resources in the community. We have an abundance of such resources
            in much of what is considered waste, over-production and ‘sloppy
            seconds.’
          </p>
          <p>
            Auroville started a collaborative research with the Science Walden
            Centre to raise human consciousness and call for a new future. With
            international diversity and willing conscious participants,
            Auroville became a perfect place for this vision to become reality.
          </p>
        </Col>
      </Row>
      <div
        style={{
          background: '#529647',
          width: '100%',
          marginBottom: '1.1rem',
          padding: '0rem',
          borderRadius: '0.55rem',
          overflow: 'hidden',
        }}
        className="hidden-on-mobile hidden-on-tablet"
      >
        <h2
          style={{
            width: '100%',
            maxWidth: 'unset',
            background: 'var(--gold2)',
            padding: '0.55rem 1.1rem',
            marginBottom: 0,
            textAlign: 'center',
          }}
        >
          Key Ideas
        </h2>
        <Row
          gutter={[
            { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
            { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          ]}
        >
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            xxl={8}
            style={{ padding: '1.1rem 0.55rem' }}
          >
            <div style={{ display: 'flex' }}>
              <GatsbyImage
                objectFit="contain"
                image={getImage(props.data.pic1)}
                style={{
                  maxWidth: '240px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            </div>
            <p
              style={{
                color: 'var(--gold2)',
                textAlign: 'center',
              }}
            >
              A currency that allows resources to easily circulate within the
              community made possible through a digital platform.
            </p>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            xxl={8}
            style={{ padding: '1.1rem 0.55rem' }}
          >
            <div style={{ display: 'flex' }}>
              <GatsbyImage
                objectFit="contain"
                image={getImage(props.data.pic2)}
                style={{
                  maxWidth: '240px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            </div>
            <p
              style={{
                color: 'var(--gold2)',
                textAlign: 'center',
              }}
            >
              A new form of value that is created when an under-utilized
              resource is circulated in the community through exercising
              goodwill.
            </p>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            xxl={8}
            style={{ padding: '1.1rem 0.55rem' }}
          >
            <div style={{ display: 'flex' }}>
              <GatsbyImage
                objectFit="contain"
                image={getImage(props.data.pic3)}
                style={{
                  maxWidth: '240px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            </div>
            <p
              style={{
                color: 'var(--gold2)',
                textAlign: 'center',
              }}
            >
              A community-based credit that encourages community members to
              intimately and meaningfully interact with each other.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
